import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";

import * as Api from "../../api";

import { connect } from "react-redux";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import Page from "../../components/Page";
import UserEnquiriesTableList from "../../components/UserEnquiriesTableList";
import FromToDatePicker from "../../components/FromToDatePicker";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { THEME_MODE } from "../../constants/Theme";

const UserEnquiries = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [filterUserList, setFilterUserList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getUserEnquiries(1);
  }, []);

  useEffect(() => {
    let list = JSON.parse(JSON.stringify(userList));
    if (fromDate && toDate) {
      setFilterUserList(
        list.filter((x) => x.date >= fromDate && x.date <= toDate)
      );
    } else {
      if (fromDate) {
        setFilterUserList(list.filter((x) => x.date == fromDate));
      } else if (toDate) {
        setFilterUserList(list.filter((x) => x.date == toDate));
      } else {
        setFilterUserList(list);
      }
    }
  }, [fromDate, toDate]);

  const removeDupes = (arr, map = new Map()) => {
    arr.forEach((o) => map.set(o.phoneNumber, o));

    return [...map.values()];
  };

  const getUserEnquiries = (pageNo) => {
    setLoading(true);
    let filter = `?pageSize=1000000000000&pageNum=${pageNo}&filter__idRestaurant=${props.restaurantId}`;
    Api.getUserEnquiries(filter).then((response) => {
      if (response.success) {
        let list = response.data.rows;
        if (list.length) {
          list = removeDupes(list);
        }
        list = list.sort(function (a, b) {
          return (
            moment(a.createdAt).format("YYYYMMDDHHmmss") -
            moment(b.createdAt).format("YYYYMMDDHHmmss")
          );
        });

        let sortList = [];

        list.reverse().map((item, index) => {
          let date = moment(item.createdAt).format("DD MMM Y");
          let dataIndex = sortList.findIndex((x) => x?.date === date);
          if (dataIndex >= 0) {
            sortList[dataIndex]["list"] = [
              ...sortList[dataIndex]["list"],
              item,
            ];
          } else {
            sortList.push({ date: date, list: [item] });
          }
        });

        setUserList(sortList);
        setFilterUserList(sortList);
        setPageNum(pageNo);
        setTotalPages(response.data.pages);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onResetClick = () => {
    setFromDate(null);
    setToDate(null);
    setError(null);
  };

  return (
    <Container page={"settings"} p={1.5} fpx={2} fpy={0.5}>
      {loading ? (
        <Loader />
      ) : (
        <Grid px={2} mb={2}>
          <Grid mb={2} container item xs={12}>
            <Grid item xs={6}>
              <Box
                onClick={() => navigate("/analytics")}
                sx={{ cursor: "pointer", width: "fit-content" }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
              <Grid>
                <FromToDatePicker
                  themeMode={props.themeMode}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  error={error}
                  showReset={true}
                  showIcon={true}
                  onReset={onResetClick}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            {filterUserList.map((x, i) => {
              return (
                <Box>
                  <Box mb={1} mt={1.5}>
                    <Typography
                      sx={{
                        color: theme_mode["text"],
                        fontFamily: "InterSemiBold",
                        fontSize: 16,
                      }}
                    >
                      {x.date}
                    </Typography>
                  </Box>
                  <UserEnquiriesTableList
                    themeMode={props.themeMode}
                    itemList={x.list}
                  />
                </Box>
              );
            })}

            {filterUserList.length ? null : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 300,
                }}
              >
                <Typography
                  py={8}
                  sx={{
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "InterSemiBold",
                    textAlign: "center",
                  }}
                >
                  No User Enquiries
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      {totalPages > 1 && filterUserList.length ? (
        <Page
          themeMode={props.themeMode}
          totalPages={totalPages}
          pageNum={pageNum}
          onSelectPage={(value) => getUserEnquiries(value)}
        />
      ) : null}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(UserEnquiries);
