import React, { useEffect, useState } from "react";
import Sockette from "sockette";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./TableOrders.module.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";

import { connect } from "react-redux";
import {
  updateDiningSelectedFloor,
  updateTableOrderList,
} from "../../redux/actions/userDataActions";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import TableOrderTableLayout from "../../components/TableOrderTableLayout";

import { THEME_MODE } from "../../constants/Theme";
import { DINEIN, FIREBASE_CONFIG, NAVBAR_HEIGHT, TABLE } from "../../constants";

let FLOOR_WIDTH = 150;
let TOP_PADDING = 8;

const fapp = initializeApp(FIREBASE_CONFIG);
const db = getFirestore(fapp);

const TableOrders = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [showFloor, setShowFloor] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(props.diningSelectedFloor);
  const [tableType, setTableType] = useState(props.restaurantDetail?.tableType);
  const [tableViewWidth, setTableViewWidth] = useState(window.innerWidth);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  let CONTENT_HEIGHT =
    window.innerHeight -
    NAVBAR_HEIGHT -
    (tableType == "layout" ? TOP_PADDING * 2 : 25);

  useEffect(() => {
    const q = query(
      collection(db, "restaurant", props.restaurantId, "orders"),
      where("orderStatus", "in", [
        "placed_order",
        "preparing",
        "pending_payment",
      ]),
      where("orderType", "in", [DINEIN, TABLE])
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let orders = querySnapshot.docs.map((doc) => doc.data());
      let orders1 = JSON.parse(JSON.stringify(orders));
      setOrderList(orders1);
      props.updateTableOrderList(orders1);
      setIsLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const offline_api_url = `http://localhost:${process.env.REACT_APP_OFFLINE_API_PORT}`;

    const OFFLINE_WS_PORT = process.env.REACT_APP_OFFLINE_WS_PORT;
    if (!OFFLINE_WS_PORT) {
      throw Error("Environment Variable REACT_APP_OFFLINE_WS_PORT is missing");
    }
    //TODO: Construct URL based on ip or if admin using localhost
    const OFFLINE_WS_API = `ws://${props.ipAddress}:${props.port}`;

    //TODO: Get config from offline settings
    const offline_enabled = props.offlineEnable;

    let offline_ws;
    if (offline_enabled) {
      offline_ws = new Sockette(OFFLINE_WS_API, {
        timeout: 10000,
        maxAttempts: Infinity,
        onopen: (m) => {
          console.info("offline ws connected");
          setMsgAlert({
            open: true,
            message: "Offline Server Connected",
            msgType: "success",
          });
        },
        onreconnect: (m) => {
          console.info("offline ws reconnecting");
          setMsgAlert({
            open: true,
            message: "Offline Server Reconnecting",
            msgType: "error",
          });
        },
        onclose: (m) => {
          console.info("offline ws closed");
          setMsgAlert({
            open: true,
            message: "Offline Server Closed",
            msgType: "error",
          });
        },
        onerror: (m) => {
          console.info("offline ws error:", m);
          setMsgAlert({
            open: true,
            message: "Offline Server Error",
            msgType: "error",
          });
        },
        onmessage: (m) => {
          let offlineData;
          try {
            offlineData = JSON.parse(m.data);
          } catch (e) {
            return console.log("Invalid offlineData received", e.data);
          }
          if (!offlineData) {
            return console.log("Bad offlineData received", offlineData);
          }

          //TODO: Parse data and use it
          const data = offlineData?.data;
          setOrderList(data);
          props.updateTableOrderList(data);
        },
      });
    }

    //TODO: If connected call local APIs instead of online APIs. Use this check
    if (offline_ws?.readyState === WebSocket.OPEN) {
      console.log("localMessageWs is connected");
    } else {
      console.log("localMessageWs is not connected");
    }
  }, [props.offlineEnable, props.ipAddress, props.port]);

  useEffect(() => {
    props.updateDiningSelectedFloor(selectedFloor);
  }, [selectedFloor]);

  useEffect(() => {
    if (document.getElementById("tableViewId")) {
      setTableViewWidth(document.getElementById("tableViewId").clientWidth);
    }
  }, [props.drawerOpen, floorList, selectedFloor]);

  useEffect(() => {
    if (props.floorTableList.length) {
      let list = JSON.parse(JSON.stringify(props.floorTableList));
      setFloorList(list);
      if (list.length) {
        if (props.diningSelectedFloor) {
          let data_index = [...list].findIndex(
            (x) => x._id == props.diningSelectedFloor?._id
          );
          if (data_index >= 0) {
            setSelectedFloor(list[data_index]);
          }
        } else {
          setSelectedFloor(list[0]);
        }
      }
    }
  }, [props.floorTableList]);

  const onSelectFloor = (val) => {
    setSelectedFloor(val);
  };
  const navigateToTableDetails = (id) => {
    navigate("/dining/details/" + id);
  };

  return (
    <Container
      page={"table_orders"}
      showFooter={false}
      p={tableType == "layout" ? 0 : 1.5}
    >
      {isLoading ? (
        <Box
          className={styles.loadingView}
          sx={{ backgroundColor: theme_mode.bg }}
        >
          <Loader />
        </Box>
      ) : null}
      <Grid
        id="mainViewId"
        container
        item
        xs={12}
        justifyContent={"space-between"}
        sx={{ overflow: "hidden" }}
      >
        {tableType == "layout" ? (
          <TableOrderTableLayout
            page={"TableOrders"}
            tableEditable={false}
            floorList={floorList}
            orderList={orderList}
            selectedFloor={props.diningSelectedFloor}
            setSelectedFloor={setSelectedFloor}
            isLoading={isLoading}
            onTableClick={navigateToTableDetails}
          />
        ) : (
          <Box
            id="tableViewId"
            style={{ flex: 1, height: CONTENT_HEIGHT, overflow: "auto" }}
          >
            <Box className={styles.tableListView}>
              {selectedFloor?.tableDetails.map((tble, index) => {
                let orderData = null;
                let filterOrder = orderList.filter((x) =>
                  (Array.isArray(x._idRestaurantTable) &&
                  x._idRestaurantTable?.length
                    ? x._idRestaurantTable
                    : []
                  ).includes(tble._id)
                );

                let course_name = null;

                if (filterOrder.length) {
                  orderData = filterOrder[0];
                  orderData.minutes = moment(orderData.createdAt).fromNow();
                  let cart_course = [];
                  let course_id = orderData?.courseAway;
                  cart_course = props.restaurantCourseList.filter(
                    (x) => x._id == course_id
                  );
                  if (cart_course.length) {
                    if (cart_course[0]?.name) {
                      course_name = cart_course[0]?.name;
                    }
                  }
                }

                let notSendOrderData = null;

                if (orderData == null) {
                  let orders = [...props.diningDetailTableOrders];
                  let index1 = orders.findIndex((x) =>
                    x._idRestaurantTable.includes(tble._id)
                  );
                  if (index1 >= 0) {
                    notSendOrderData = orders[index1];
                  }
                }
                let table_width = tableViewWidth / 8;
                return (
                  <Box
                    key={index}
                    pb={1.5}
                    pr={1.5}
                    className={styles.tableMainView}
                    style={{
                      width: table_width,
                      height: table_width,
                    }}
                  >
                    <Box
                      onClick={() => navigateToTableDetails(tble._id)}
                      className={styles.tableView}
                      style={{
                        backgroundColor:
                          orderData || notSendOrderData
                            ? theme_mode.tableActiveOrder
                            : theme_mode.orderTableBg,
                      }}
                    >
                      <Box
                        className={styles.tableNoView}
                        style={{
                          border:
                            orderData || notSendOrderData
                              ? "1.5px solid " + theme_mode.text
                              : "1.5px solid " + theme_mode.lightText,
                        }}
                      >
                        <Typography
                          className={styles.tableNoText}
                          style={{
                            color:
                              orderData || notSendOrderData
                                ? theme_mode.text
                                : theme_mode.lightText,
                            fontSize: table_width / 8,
                          }}
                        >
                          {tble.name}
                        </Typography>
                      </Box>
                      {orderData ? (
                        <Box
                          mt={0.5}
                          className={styles.tableOrderMin}
                          style={{
                            fontSize: table_width / 12,
                            color: theme_mode.blackText,
                          }}
                        >
                          {orderData.minutes}
                        </Box>
                      ) : null}
                      {course_name != null ? (
                        orderData ? (
                          <Box
                            mt={0.5}
                            py={"3%"}
                            className={styles.tableOrderItems}
                            style={{
                              backgroundColor: theme_mode.black,
                              fontSize: table_width / 14,
                              color: theme_mode.btnText,
                            }}
                          >
                            {course_name}
                          </Box>
                        ) : null
                      ) : null}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

        {showFloor ? (
          <Box
            my={tableType == "layout" ? `${TOP_PADDING}px` : 0}
            sx={{
              width: FLOOR_WIDTH,
              height: CONTENT_HEIGHT,
              overflow: "auto",
            }}
          >
            <Box
              className={styles.floorListView}
              style={{
                backgroundColor: theme_mode.orderTableFloorBg,
              }}
            >
              {floorList.map((floor, index) => {
                let list = floor.tableDetails.map((x) => x._id);

                let orderCount = 0;
                orderList
                  .filter((x) =>
                    (Array.isArray(x._idRestaurantTable) &&
                    x._idRestaurantTable?.length
                      ? x._idRestaurantTable
                      : []
                    ).some((t) => list.includes(t))
                  )
                  .map((o) => {
                    orderCount = orderCount + o._idRestaurantTable.length;
                  });

                let height = 120;

                let fntSize = FLOOR_WIDTH / 7.5;
                let fntSize1 = height / 7;
                fntSize = fntSize1 > fntSize ? fntSize : fntSize1;

                return (
                  <Box
                    key={index}
                    onClick={() => onSelectFloor(floor)}
                    className={styles.floorMainView}
                    style={{
                      backgroundColor:
                        selectedFloor?._id == floor._id
                          ? theme_mode.floorBg
                          : theme_mode.orderTableFloorBg,
                      borderLeft:
                        selectedFloor?._id == floor._id
                          ? "6px solid" + theme_mode.tableActiveOrder
                          : "0",
                    }}
                  >
                    <Box
                      className={styles.floorView}
                      style={{
                        minHeight: height,
                      }}
                    >
                      {orderCount > 0 ? (
                        <Box
                          mb={0.5}
                          className={styles.floorOrderCount}
                          style={{
                            backgroundColor: theme_mode.tableActiveOrder,
                            width: fntSize + 10,
                            height: fntSize + 10,
                          }}
                        >
                          <Typography
                            className={styles.floorOrderCountText}
                            style={{
                              color: theme_mode.text,
                              fontSize: { xl: fntSize - 5, md: fntSize - 3 },
                            }}
                          >
                            {orderCount}
                          </Typography>
                        </Box>
                      ) : null}
                      <Typography
                        className={styles.floorText}
                        style={{
                          color:
                            selectedFloor?._id == floor._id
                              ? theme_mode.lightText
                              : theme_mode.text,
                          fontSize: { xl: fntSize - 5, md: fntSize },
                        }}
                      >
                        {floor.name}
                      </Typography>
                    </Box>
                    {floorList.length == index + 1 ||
                    selectedFloor?._id == floor._id ? null : (
                      <Box
                        className={styles.borderLine}
                        style={{
                          border: "1px solid " + theme_mode.borderBottom4,
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : null}
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTableOrderList: (data) => dispatch(updateTableOrderList(data)),
    updateDiningSelectedFloor: (data) =>
      dispatch(updateDiningSelectedFloor(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    drawerOpen: state.userData.drawerOpen,
    restaurantId: state.userData.restaurantId,
    diningDetailTableOrders: state.userData.diningDetailTableOrders,
    diningSelectedFloor: state.userData.diningSelectedFloor,
    floorTableList: state.userData.floorTableList,
    restaurantCourseList: state.userData.restaurantCourseList,
    restaurantDetail: state.userData.restaurantDetail,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableOrders);
