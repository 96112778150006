import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import styles from "./Course.module.css";

import { connect } from "react-redux";
import {
  updateRestaurantCategoryList,
  updateRestaurantCourseList,
} from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import SettingTextField from "../../components/SettingTextField";
import AlertMsg from "../../components/AlertMsg";
import Page from "../../components/Page";
import SelectizeTextField from "../../components/SelectizeTextField";
import ConfirmAlert from "../../components/ConfirmAlert";

import { THEME_MODE } from "../../constants/Theme";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit3.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete3.svg";

import { FOOTER_XPADDING } from "../../constants";

const Course = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState("");
  const [courseCategories, setCourseCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [editCourse, setEditCourse] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [error, setError] = useState(null);
  const [editError, setEditError] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [confirmDeleteData, setConfirmDeleteData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    setIsLoading(true);
    let filter = "all";
    Api.getCategoryList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        const data = response.data.rows.sort((a, b) => a?.order - b?.order);
        props.updateRestaurantCategoryList(data);
        setCategoryList(data);
        getCourseList(1, data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setIsLoading(false);
      }
    });
  };

  const getCourseList = (num, category_list) => {
    setIsLoading(true);
    // let filter = "?pageSize=10&pageNum=" + num;
    let filter = "/all";
    Api.getCourseList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        props.updateRestaurantCourseList(data);

        let list = [];
        data.map((y) => {
          let cat_list = y?._idRestaurantCategory.length
            ? category_list.filter((item1) =>
                y._idRestaurantCategory.includes(item1._id)
              )
            : [];
          list.push({ ...y, _idRestaurantCategory: cat_list });
        });
        setCourseList(list);
        // setPageNum(num);
        // setTotalPages(response.data.pages);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onSelectPage = (value) => {
    getCourseList(value, categoryList);
  };

  const onEditClick = (x) => {
    setEditError(null);
    setEditCourse(x);
    if (document.getElementById("courseId" + x._id)) {
      setTimeout(() => {
        document
          .getElementById("courseId" + x._id)
          .getElementsByTagName("input")[0]
          .focus();
      }, 100);
    }
  };

  const onDeleteClick = (val) => {
    setConfirmDeleteData(val);
  };

  const confirmDeleteMenu = () => {
    setBtnLoading(true);
    Api.deleteCourse(props.restaurantId, confirmDeleteData._id).then(
      (response) => {
        if (response.success) {
          setMsgAlert({
            open: true,
            message: "Deleted Successfully",
            msgType: "success",
          });
          let list = [...courseList];
          setCourseList(list.filter((x) => x._id != confirmDeleteData._id));
          props.updateRestaurantCourseList(
            props.restaurantCourseList.filter(
              (x) => x._id != confirmDeleteData._id
            )
          );
          setConfirmDeleteData(null);
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setBtnLoading(false);
      }
    );
  };

  const onAddCourse = () => {
    let err = null;
    if (course.trim() == "") {
      err = { course: "Course is required" };
    }

    if (courseCategories.length == 0) {
      err = { ...err, catgeories: "Catgeories is required" };
    }

    setError(err);
    if (err != null) {
      return;
    }

    addCourse({
      name: course,
      _idRestaurantCategory: courseCategories,
    });
  };

  const onUpdateCourse = () => {
    let err = null;
    if (editCourse.name.trim() == "") {
      err = { course: "Course is required" };
    }

    if (editCourse._idRestaurantCategory.length == 0) {
      err = { ...err, catgeories: "Catgeories is required" };
    }

    setEditError(err);
    if (err != null) {
      return;
    }
    addCourse(editCourse);
  };

  const addCourse = (data) => {
    setIsLoading(true);
    let data1 = {
      _idRestaurant: props.restaurantId,
      name: data.name,
      _idRestaurantCategory: data._idRestaurantCategory.map((x) => x._id),
    };
    let datas = JSON.stringify(data1);
    Api.addUpdateCourse(
      datas,
      props.restaurantId,
      data?._id ? data._id : null
    ).then((response) => {
      if (response.success) {
        setCourse("");
        setCourseCategories([]);
        let list = [...courseList];
        if (data?._id) {
          let findIndex = list.findIndex((x) => x._id == editCourse._id);
          if (findIndex >= 0) {
            list[findIndex]["name"] = editCourse.name;
            list[findIndex]["_idRestaurantCategory"] =
              editCourse._idRestaurantCategory;
            setEditCourse(null);

            let list1 = [...props.restaurantCourseList];
            let findIndex1 = list1.findIndex((x) => x._id == editCourse._id);
            if (findIndex1 >= 0) {
              list1[findIndex]["name"] = editCourse.name;
              list1[findIndex]["_idRestaurantCategory"] =
                data1._idRestaurantCategory;
              props.updateRestaurantCourseList(list1);
            }
          }
        } else {
          props.updateRestaurantCourseList([
            ...props.restaurantCourseList,
            response.data,
          ]);
          list.push({
            ...response.data,
            _idRestaurantCategory: data._idRestaurantCategory,
          });
        }
        setCourseList(list);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onSetCourse = (val) => {
    let data = { ...editCourse };
    data.name = val;
    setEditCourse(data);
  };

  const onSetCourseCategories = (val) => {
    let data = { ...editCourse };
    data._idRestaurantCategory = val;
    setEditCourse(data);
  };

  const onCloseConfirmDelete = () => {
    setConfirmDeleteData(null);
  };

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Grid
          container
          item
          md={12}
          xs={12}
          p={1.5}
          style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
        >
          <Box onClick={() => navigate("/settings")} sx={{ cursor: "pointer" }}>
            <BackBtnIcon width={33} height={33} />
          </Box>
          <Grid container item xs={12} mt={2} justifyContent={"space-between"}>
            <Grid
              item
              xs={12}
              md={5.2}
              p={props.themeMode == "dark" ? 2 : 0}
              mb={props.themeMode == "dark" ? 1 : 0}
              style={{ backgroundColor: theme_mode.inputBg5, borderRadius: 15 }}
            >
              <SettingTextField
                page={"course"}
                value={course}
                setValue={setCourse}
                label={"Add Courses"}
                placeholder={"Type course name"}
                required={true}
                variant={"filled"}
                backgroundColor={theme_mode.inputBg4}
                error={error?.course}
              />
              <Box mt={1}>
                <SelectizeTextField
                  page={"course"}
                  tagColor={theme_mode.inputBg5}
                  value={courseCategories}
                  onChange={(val) => {
                    setCourseCategories(val);
                  }}
                  backgroundColor={theme_mode.inputBg4}
                  options={categoryList}
                  variant={"filled"}
                  label={""}
                  placeholder={
                    courseCategories.length ? "" : "Select Categories"
                  }
                  error={error?.catgeories}
                />
              </Box>
              <Box
                my={2}
                onClick={onAddCourse}
                className={styles.addBtn}
                style={{ backgroundColor: theme_mode.btnBg4 }}
              >
                <Typography
                  className={styles.addBtnText}
                  style={{ color: theme_mode.btnText }}
                >
                  Add Course
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6.6}
              p={props.themeMode == "dark" ? 2 : 0}
              mb={props.themeMode == "dark" ? 1 : 0}
              style={{ backgroundColor: theme_mode.inputBg5, borderRadius: 15 }}
            >
              {courseList.length ? (
                <Box className={styles.courseListMainView}>
                  <Typography
                    className={styles.courseTitle}
                    sx={{ color: theme_mode.text }}
                  >
                    Courses
                  </Typography>
                  <Box
                    mt={1}
                    style={{
                      height: window.innerHeight - 220,
                      overflow: "auto",
                      borderRadius: 10,
                    }}
                  >
                    {courseList.map((x, i) => {
                      return (
                        <Box
                          key={i}
                          className={styles.courseView}
                          style={{ backgroundColor: theme_mode.inputBg4 }}
                          pr={2}
                          pl={1}
                          mb={1}
                          pt={editCourse?._id == x._id ? 1.5 : 0}
                          pb={1.5}
                        >
                          <Box
                            className={styles.courseNameView}
                            id={"courseId" + x._id}
                            pr={2}
                          >
                            <Box mb={editCourse?._id == x._id ? 1 : 0}>
                              <SettingTextField
                                page={"course"}
                                editable={editCourse?._id == x._id}
                                backgroundColor={theme_mode.inputBg5}
                                disabledBg={theme_mode.inputBg4}
                                disabledColor={theme_mode.cardText}
                                value={
                                  editCourse?._id == x._id
                                    ? editCourse.name
                                    : x.name
                                }
                                setValue={onSetCourse}
                                label={""}
                                placeholder={""}
                                required={true}
                                variant={"filled"}
                                error={
                                  editCourse?._id == x._id
                                    ? editError?.course
                                    : ""
                                }
                              />
                            </Box>
                            <Box pl={editCourse?._id == x._id ? 0 : 1.5}>
                              {editCourse?._id == x._id ? (
                                <SelectizeTextField
                                  page={"course"}
                                  tagColor={props.themeMode == 'light' ? '#CCCCCC' : theme_mode.inputBg4}
                                  value={editCourse._idRestaurantCategory}
                                  onChange={onSetCourseCategories}
                                  backgroundColor={theme_mode.inputBg5}
                                  options={categoryList}
                                  variant={"filled"}
                                  label={""}
                                  placeholder={
                                    editCourse._idRestaurantCategory?.length
                                      ? ""
                                      : "Select Categories"
                                  }
                                  error={editError?.catgeories}
                                />
                              ) : (
                                <Box className={styles.catListView}>
                                  {x._idRestaurantCategory.map((c, i2) => {
                                    return (
                                      <Box
                                        key={i2}
                                        style={{
                                          backgroundColor: theme_mode.inputBg5,
                                        }}
                                        className={styles.catListNameView}
                                        mb={0.5}
                                        mr={1}
                                        px={1}
                                        py={0.4}
                                      >
                                        <Typography
                                          className={styles.catListNameText}
                                          style={{
                                            color: theme_mode.text,
                                          }}
                                        >
                                          {c.name}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                  {x._idRestaurantCategory.length == 0 ? (
                                    <Typography
                                      className={styles.catListNameText}
                                    >
                                      No Categories
                                    </Typography>
                                  ) : null}
                                </Box>
                              )}
                            </Box>
                          </Box>
                          {editCourse?._id == x._id ? (
                            <Box
                              onClick={onUpdateCourse}
                              className={styles.updateBtn}
                              style={{
                                backgroundColor: theme_mode.btnBg4,
                              }}
                            >
                              <Typography
                                className={styles.updateBtnText}
                                style={{ color: theme_mode.btnText }}
                              >
                                Update
                              </Typography>
                            </Box>
                          ) : (
                            <>
                              <Box
                                ml={1}
                                mr={2}
                                className={styles.actionBtn}
                                onClick={() => onEditClick(x)}
                                sx={{
                                  path: {
                                    stroke: theme_mode.iconBg,
                                  },
                                }}
                              >
                                <EditIcon width={20} height={20} />
                              </Box>
                              <Box
                                className={styles.actionBtn}
                                onClick={() => onDeleteClick(x)}
                                sx={{
                                  path: {
                                    stroke: theme_mode.iconBg,
                                  },
                                }}
                              >
                                <DeleteIcon width={20} height={20} />
                              </Box>
                            </>
                          )}
                        </Box>
                      );
                    })}
                  </Box>

                  {totalPages > 0 ? (
                    <Page
                      themeMode={props.themeMode}
                      totalPages={totalPages}
                      pageNum={pageNum}
                      onSelectPage={onSelectPage}
                    />
                  ) : null}
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      )}
      <ConfirmAlert
        confirmDelete={confirmDeleteData != null}
        title={"Are you sure?"}
        content={
          <Typography
            style={{
              fontFamily: "InterMedium",
              textAlign: "center",
            }}
          >
            Are you sure you want to remove <br />
            {confirmDeleteData == null ? null : confirmDeleteData?.name}
          </Typography>
        }
        firstBtn={"No"}
        secondBtn={btnLoading ? "Please Wait" : "Yes ! Delete it"}
        btnLoading={btnLoading}
        onCloseConfirmDelete={onCloseConfirmDelete}
        onConfirmDelete={confirmDeleteMenu}
        themeMode={props.themeMode}
      />
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantCourseList: (data) =>
      dispatch(updateRestaurantCourseList(data)),
    updateRestaurantCategoryList: (data) =>
      dispatch(updateRestaurantCategoryList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantId: state.userData.restaurantId,
    restaurantCourseList: state.userData.restaurantCourseList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Course);
