import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import styles from "./OrderHistoryDetailModal.module.css";

import OrderModalItemList from "./OrderModalItemList";

import { CURRENCY_SYMBOL, DELIVERY, PICKUP } from "../constants";

import { ReactComponent as CloseIcon } from "../assets/images/close_black.svg";

import { THEME_MODE } from "../constants/Theme";

let BOTTOM_HEIGHT = 75;

const OrderHistoryDetailModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [headerHeight, setHeaderHeight] = useState(0);

  let { data } = props;

  useEffect(() => {
    if (props.data) {
      if (document.getElementById("headerViewId")) {
        setHeaderHeight(document.getElementById("headerViewId").offsetHeight);
      }
    }
  }, [props.data]);

  return (
    <Modal
      className={styles.modelView}
      open={!(props.data == null)}
      onClose={props.onClose}
    >
      {props.data ? (
        <Box
          sx={{
            backgroundColor: theme_mode.modal,
            width: { md: "40%", xs: "98%" },
            borderRadius: 3,
            "&:focus": {
              outline: "none",
            },
            overflow: "hidden",
          }}
        >
          <Box
            className={styles.dataView}
            sx={{ backgroundColor: theme_mode.modalHeaderBg }}
            id={"headerViewId"}
            px={3}
            pt={1.5}
            pb={2}
          >
            <Box style={{ flex: 1 }}>
              <Typography
                className={styles.orderIdText}
                sx={{ color: theme_mode.lightText }}
              >
                Order #{data._id.toString().substr(-5)}
              </Typography>
              <Typography
                className={styles.orderDateText}
                sx={{ color: theme_mode.lightText }}
              >
                {data.date}
              </Typography>
              <Typography
                className={styles.nameText}
                sx={{ color: theme_mode.lightText }}
              >
                {data.name}
              </Typography>
              <Typography
                mt={1}
                className={styles.priceText}
                sx={{ color: theme_mode.lightText }}
              >
                {data.price.currency}: {data.price.value.toFixed(2)}
              </Typography>
            </Box>
            <Box className={styles.headerRightView}>
              <Box onClick={props.onClose} sx={{ cursor: "pointer" }}>
                <CloseIcon width={35} height={35} />
              </Box>
              <Box
                className={styles.orderTypeView}
                sx={{
                  backgroundColor:
                    data.orderType == DELIVERY || data.orderType == PICKUP
                      ? theme_mode.onlineOrder
                      : theme_mode.tableOrder,
                }}
              >
                <Typography
                  className={styles.orderTypeText}
                  sx={{ color: theme_mode.lightText }}
                >
                  {data.orderType == DELIVERY || data.orderType == PICKUP
                    ? "Online Order"
                    : "Table Order"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className={styles.menuListView}
            sx={{
              maxHeight: window.innerHeight - headerHeight - BOTTOM_HEIGHT - 100,
            }}
          >
            <Typography
              px={3}
              mt={2}
              className={styles.orderItemText}
              sx={{ color: theme_mode.text }}
            >
              Ordered Items
            </Typography>
            {data.cart.map((x, i) => {
              return (
                <OrderModalItemList
                  key={i}
                  item={x}
                  themeMode={props.themeMode}
                  lastIndex={data.cart.length == i + 1}
                  page={"orderHistoryDetail"}
                />
              );
            })}
          </Box>
          <Box
            sx={{ height: BOTTOM_HEIGHT, backgroundColor: theme_mode.card3 }}
            className={styles.btnMainView}
          >
            <Box
              className={styles.btnView}
              sx={{ backgroundColor: theme_mode.greenBg }}
            >
              <Typography
                className={styles.btnText}
                sx={{ color: theme_mode.lightText }}
              >
                REFUND
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Modal>
  );
};

export default OrderHistoryDetailModal;
