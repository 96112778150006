import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";
import moment from "moment";

import { THEME_COLOR3 } from "../constants";
import { THEME_MODE } from "../constants/Theme";
import { allDays } from "./AddRestaurant";

import "./../assets/css/custom.css";

const theme = (theme_mode, date, format) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: theme_mode.card + " !important",
            border: "1px solid " + theme_mode.lightText,
            color: theme_mode.text,
            "& .MuiButtonBase-root, .MuiTypography-root": {
              color: theme_mode.text,
              fontFamily: "InterMedium !important",
            },
            "& .MuiClockPointer-root, .MuiClock-pin": {
              backgroundColor: theme_mode.theme + " !important",
            },
            "& .MuiClock-amButton": {
              backgroundColor:
                date != "" && moment(date, format).format("A") == "AM"
                  ? theme_mode.theme
                  : "transparent",
            },
            "& .MuiClock-pmButton": {
              backgroundColor:
                date != "" && moment(date, format).format("A") == "PM"
                  ? theme_mode.theme
                  : "transparent",
            },
            "& .MuiClock-amButton:hover, .MuiClock-pmButton:hover": {
              backgroundColor: theme_mode.inputDisabledBg + " !important",
            },
            "& .MuiClockPointer-thumb": {
              backgroundColor: theme_mode.theme + " !important",
              border: "16px solid " + theme_mode.theme + " !important",
              color: theme_mode.black,
            },
            "& .Mui-disabled": {
              color: theme_mode.inputDisabledBg,
            },
            "& .MuiPickersArrowSwitcher-root": {
              color: theme_mode.text,
            },
            "& .MuiClock-clock": {
              backgroundColor: theme_mode.card3 + " !important",
              color: theme_mode.text,
            },
            "& .MuiClockNumber-root": {
              color: theme_mode.text,
              fontFamily: "InterMedium !important",
            },
          },
        },
      },
    },
    palette: {
      gray: {
        main: THEME_COLOR3,
      },
      red: {
        main: "#FF0000",
      },
    },
  });

const AddRestaurantInfo = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [openingHourList, setOpeningHourList] = useState(props.openingHourList);

  const onActiveSwitch = (data) => {
    let openingHour = [...openingHourList];
    let openingHourIndex = openingHour.findIndex(
      (item) => item.day === data.day
    );
    if (openingHourIndex >= 0) {
      const active = !openingHour[openingHourIndex]["active"];
      openingHour[openingHourIndex]["active"] = active;
      if (active) {
        const { open, close } = openingHourList?.find((x) => x?.day == allDays);
        openingHour[openingHourIndex]["open"] = open;
        openingHour[openingHourIndex]["close"] = close;
      }
      setOpeningHourList(openingHour);
    }
  };

  const openCloseTime = (val, data, type) => {
    let openingHour = [...openingHourList];
    if (data.day == allDays) {
      openingHour = openingHour.map((item) => {
        item[type] = val;
        return item;
      });
    } else {
      let openingHourIndex = openingHour.findIndex(
        (item) => item.day === data.day
      );
      if (openingHourIndex >= 0) {
        openingHour[openingHourIndex][type] = val;
      }
    }
    setOpeningHourList(openingHour);
  };

  const onPrevBtnClick = () => {
    props.resOpeningHours("", "prev");
  };

  const onSaveBtnClick = () => {
    props.resOpeningHours(openingHourList, "next");
  };

  return (
    <Grid>
      <Grid
        container
        item
        component="form"
        noValidate
        autoComplete="off"
        justifyContent="center"
      >
        {openingHourList.map((item, index) => {
          return (
            <Grid
              container
              item
              xs={9}
              key={index}
              sx={{ alignItems: "center", minHeight: 60 }}
              mt={2}
            >
              <Grid container direction="row" item xs={12} md={4} mt={2}>
                <Grid item xs={12} md={6}>
                  <label
                    className="day-label"
                    style={{
                      color: theme_mode.text,
                      fontFamily: "InterSemiBold",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.day}
                  </label>
                </Grid>
                <Grid item xs={12} md={6}>
                  {item?.day == allDays ? null : (
                    <ThemeProvider theme={theme(theme_mode, "", "")}>
                      <Switch
                        checked={item.active}
                        onChange={() => onActiveSwitch(item)}
                        color={"red"}
                        size="small"
                        sx={{
                          "& .MuiSwitch-track": {
                            height: 15,
                            backgroundColor: theme_mode.text9 + " !important",
                            marginTop: -0.3,
                          },
                          "& .MuiSwitch-thumb": {
                            backgroundColor: "#999",
                          },
                          "& .Mui-checked .MuiSwitch-thumb": {
                            backgroundColor: "red !important",
                          },
                        }}
                      />
                    </ThemeProvider>
                  )}{" "}
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={12} md={7}>
                {item.active == 1 ? (
                  <ThemeProvider
                    theme={theme(theme_mode, item.open, "hh:mm a")}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ paddingRight: 5, marginTop: { xs: 2, md: 0 } }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time Open"
                          value={item.open}
                          inputFormat="hh:mm a"
                          inputProps={{
                            autoComplete: "none",
                            readOnly: true,
                            sx: {
                              color: theme_mode.text,
                              fontSize: 13,
                              fontFamily: "InterSemiBold",
                              marginTop: 0.7,
                              marginBottom: 0.7,
                            },
                          }}
                          onChange={(val) => openCloseTime(val, item, "open")}
                          renderInput={(params) => (
                            <TextField
                              focused
                              fullWidth
                              color="gray"
                              variant="standard"
                              InputLabelProps={{
                                style: { fontFamily: "InterRegular" },
                              }}
                              sx={{
                                svg: {
                                  color: theme_mode.iconBg,
                                  fontSize: 18,
                                  marginTop: 0.7,
                                  marginBottom: 0.7,
                                },
                                "& .MuiButtonBase-root": {
                                  width: 35,
                                  height: 35,
                                },
                              }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ paddingRight: 5, marginTop: { xs: 2, md: 0 } }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time Closed"
                          value={item.close}
                          inputFormat="hh:mm a"
                          inputProps={{
                            autoComplete: "none",
                            readOnly: true,
                            sx: {
                              color: theme_mode.text,
                              fontSize: 13,
                              fontFamily: "InterSemiBold",
                              marginTop: 0.7,
                              marginBottom: 0.7,
                            },
                          }}
                          onChange={(val) => openCloseTime(val, item, "close")}
                          renderInput={(params) => (
                            <TextField
                              focused
                              fullWidth
                              color="gray"
                              variant="standard"
                              InputLabelProps={{
                                style: { fontFamily: "InterRegular" },
                              }}
                              sx={{
                                svg: {
                                  color: theme_mode.iconBg,
                                  fontSize: 18,
                                  marginTop: 0.7,
                                  marginBottom: 0.7,
                                },
                                "& .MuiButtonBase-root": {
                                  width: 35,
                                  height: 35,
                                },
                              }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </ThemeProvider>
                ) : null}
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={7}
        mb={1}
      >
        <Typography
          onClick={() => onPrevBtnClick()}
          gutterBottom
          sx={styles.btnText}
        >
          Previous
        </Typography>
        <Typography
          ml={2}
          onClick={() => onSaveBtnClick()}
          gutterBottom
          sx={styles.btnText}
        >
          Save
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddRestaurantInfo;

const styles1 = (Theme) => ({
  btnText: {
    backgroundColor: Theme.theme,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "InterBold",
    fontSize: 13,
    color: Theme.text,
    borderRadius: 1,
    width: 100,
    height: 35,
  },
});
