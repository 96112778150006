import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { ReactComponent as MenuImg } from "../assets/images/file1.svg";
import { THEME_MODE } from "../constants/Theme";
import styles from "./OrderSummaryCartItem.module.css";
import { getCartItemsTotalPrice } from "../constants/CommonFunc";

const OrderModalItemList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  let item = props.item;

  // let counts = {};
  // if (item?.cartOptions?.length) {
  //   let arr = item.cartOptions.reduce((a, b) => a.concat(b), []);
  //   arr.forEach((x) => (counts[x] = (counts[x] || 0) + 1));
  // }

  let modifiers = [];
  const totalPrice = getCartItemsTotalPrice(item, []) ?? 0;
  item?.cartModifier?.map((mod1, i1) => {
    let modifier = Array.isArray(mod1) ? mod1 : JSON.parse(mod1);
    let mod = [];
    modifier?.map((y1, i2) => {
      mod.push(y1?.withExtra + " " + y1?.menuData?.name);
    });
    if (mod.length) {
      modifiers.push(mod);
    }
  });

  return (
    <Box px={3} pt={2} sx={styles.mainView}>
      {props.page == "orderHistoryDetail" ? (
        <Box mr={2}>
          {item.images?.length ? (
            <Box sx={styles.imageView}>
              <img src={item.images[0]} style={styles.imageStyle} />
            </Box>
          ) : (
            <Box sx={{ ...styles.imageView, ...styles.menuImgView }}>
              <MenuImg width={"40%"} height={"40%"} />
            </Box>
          )}
        </Box>
      ) : null}
      <Box
        pb={2}
        sx={{
          ...styles.dataView,
          borderBottom: props.lastIndex
            ? 0
            : "1px solid " + theme_mode.borderBottom3,
        }}
      >
        <Box mb={1} sx={styles.nameAddOnView}>
          <Typography sx={styles.nameText}>{item.name}</Typography>
          {item?.addOn ? (
            <Box sx={styles.addOnView}>
              <Typography sx={styles.addOnText}>
                Add on : {item.addOn}
              </Typography>
            </Box>
          ) : null}
        </Box>
        {modifiers.length
          ? modifiers.map((y2, i2) => {
              return (
                <Typography
                  key={i2}
                  mt={1}
                  sx={styles.noteText}
                  style={{ color: theme_mode.cardDescText3 }}
                >
                  -- 1 {y2.join(", ")}
                </Typography>
              );
            })
          : null}
        {/*{item?.cartOptions?.length ? (*/}
        {/*  <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>*/}
        {/*    {Object.keys(counts).map((key, index) => {*/}
        {/*      return (*/}
        {/*        <Typography key={index} sx={styles.noteText}>*/}
        {/*          {counts[key]} with {key}*/}
        {/*        </Typography>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </Box>*/}
        {/*) : null}*/}
        {item?.notes ? (
          <Typography my={1} sx={styles.noteText}>
            Notes : {item.notes}
          </Typography>
        ) : null}
        <Box sx={styles.priceQtyView}>
          <Typography sx={styles.priceText}>
            {item.quantity}x {item.price.currency}: {totalPrice?.toFixed(2)}
          </Typography>
          {props.page == "orderHistoryDetail" ? null : (
            <Typography sx={styles.qtyText}>Qty {item.quantity}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderModalItemList;

const styles1 = (Theme) => ({
  mainView: {
    display: "flex",
    flexDirection: "row",
  },
  dataView: {
    flex: 1,
  },
  imageView: {
    width: 56,
    height: 56,
    borderRadius: 28,
    overflow: "hidden",
  },
  menuImgView: {
    backgroundColor: Theme.imgBg,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageStyle: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  nameAddOnView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nameText: {
    color: Theme.cardDescText3,
    fontFamily: "InterBold",
    fontSize: 16,
  },
  addOnView: {
    border: "1px solid " + Theme.borderBottom3,
    borderRadius: 37,
    px: 1,
  },
  addOnText: {
    color: Theme.cardDescText3,
    fontFamily: "InterBold",
    fontSize: 12,
  },
  noteText: {
    color: Theme.cardDescText3,
    fontFamily: "InterMedium",
    fontSize: 12,
    lineHeight: "13px",
  },
  priceQtyView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  priceText: {
    color: Theme.cardText,
    fontFamily: "InterBold",
    fontSize: 14,
  },
  qtyText: {
    color: Theme.cardText,
    fontFamily: "InterBold",
    fontSize: 14,
  },
});
