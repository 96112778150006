import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";

import SettingTextField from "./SettingTextField";

import { ReactComponent as PlusIcon } from "../assets/images/plus2.svg";
import { ReactComponent as EditIcon } from "../assets/images/edit2.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/delete2.svg";
import { ReactComponent as Delete1Icon } from "../assets/images/delete3.svg";
import { ReactComponent as CircleIcon } from "../assets/images/circle.svg";
import { ReactComponent as CircleDotIcon } from "../assets/images/circle_dot.svg";
import { ReactComponent as ExpandVerticalIcon } from "./../assets/images/expand_vertical.svg";

import { THEME_MODE } from "../constants/Theme";
import { CURRENCY_SYMBOL } from "../constants";

import styles from "./AddModifiers.module.css";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#455A64",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

let ACTION_BTN_LIST = [
  {
    _id: "add_item",
    label: "Add Item",
    icon: <PlusIcon width={14} height={14} />,
  },
  {
    _id: "edit_item",
    label: "Edit Item",
    icon: <EditIcon width={14} height={14} />,
  },
  {
    _id: "delete",
    label: "Delete",
    icon: <DeleteIcon width={14} height={14} />,
  },
];

let ACTION_BTN_LIST2 = [
  {
    _id: "add_item",
    label: "Add Item",
  },
  {
    _id: "save_modifier",
    label: "Save Modifier",
  },
];

const ModifierItemList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  let {
    item,
    modifierName,
    setModifierName,
    modifierError,
    editItemData,
    editModifierName,
    setEditModifierName,
    editModifierError,
    initialShowHeaderOnly,
    onActionBtnClick,
    onRemoveMenuItem,
    onSortEndMenuItem,
    onModifierClick,
    editOrEditItem,
    selectedModifier,
  } = props;

  const handlerOnHeaderClick = () => {
    onModifierClick(item);
  };

  const handlerOnActionClick = (item, btn) => {
    onActionBtnClick(item, btn);
  };

  const isEditable =
    editOrEditItem === "edit_item" || editItemData?._id === item?._id;

  return (
    <Box
      mb={1}
      className={styles.modifierView}
      style={{ backgroundColor: theme_mode.bg8 }}
    >
      <SortableKnob>
        <Box
          px={1.4}
          py={1.3}
          className={styles.modifierTitleMainView}
          onClick={handlerOnHeaderClick}
          sx={{ cursor: item._id == "add" ? "default" : "pointer" }}
        >
          <Box
            className={styles.modifierTitleView}
            pr={item._id == "add" ? 0 : 2}
          >
            {item._id == "add" ? (
              <SettingTextField
                page={"addModifier"}
                value={modifierName}
                setValue={setModifierName}
                placeholder={"Enter New Modifier"}
                placeholderColor={theme_mode.placeholder}
                border={"1px solid " + theme_mode.border3}
                required={false}
                variant={"filled"}
                error={modifierError}
              />
            ) : editItemData?._id == item._id ? (
              <SettingTextField
                page={"addModifier"}
                value={editModifierName}
                setValue={setEditModifierName}
                placeholder={"Enter New Modifier"}
                placeholderColor={theme_mode.placeholder}
                border={"1px solid " + theme_mode.border3}
                required={false}
                variant={"filled"}
                error={editModifierError}
              />
            ) : (
              <Typography
                className={styles.modifierTitleText}
                style={{ color: theme_mode.text }}
              >
                {item.header}
              </Typography>
            )}
          </Box>
          {item._id == "add" ? null : (
            <ExpandVerticalIcon width={20} height={20} />
          )}
        </Box>
      </SortableKnob>
      {!initialShowHeaderOnly || selectedModifier?._id === item?._id ? (
        <>
          {isEditable ? (
            <>
              <Box
                px={1.4}
                py={1.3}
                className={styles.modifierTitleMainView}
                justifyContent="space-between"
              >
                <Box className={styles.requiredView} key={"multi_selection"}>
                  <Typography
                    className={styles.requiredText}
                    mr={1.5}
                    style={{ color: theme_mode.text9 }}
                  >
                    Multi Selection
                  </Typography>
                  <AntSwitch
                    checked={item?.multiSelection}
                    onChange={() =>
                      onActionBtnClick(item, { _id: "multi_selection" })
                    }
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </Box>
                <Box
                  ml={2}
                  className={styles.requiredView}
                  key={"extra_allowed"}
                >
                  <Typography
                    className={styles.requiredText}
                    mr={1.5}
                    style={{ color: theme_mode.text9 }}
                  >
                    Extra Allowed
                  </Typography>
                  <AntSwitch
                    checked={item?.extraAllowed}
                    onChange={() =>
                      onActionBtnClick(item, { _id: "extra_allowed" })
                    }
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </Box>
                <Box ml={2} className={styles.requiredView} key={"required"}>
                  <Typography
                    className={styles.requiredText}
                    mr={1.5}
                    style={{ color: theme_mode.text9 }}
                  >
                    Is Required
                  </Typography>
                  <AntSwitch
                    checked={item.required}
                    onChange={() => onActionBtnClick(item, { _id: "required" })}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </Box>
              </Box>
              <Box
                px={1.4}
                py={1.3}
                className={styles.modifierTitleMainView}
                justifyContent="space-between"
              >
                <Box className={styles.requiredView} key={"add_up_prices"}>
                  <Typography
                    className={styles.requiredText}
                    mr={1.5}
                    style={{ color: theme_mode.text9 }}
                  >
                    Add Up Prices
                  </Typography>
                  <AntSwitch
                    checked={item?.addUpPrices}
                    onChange={() =>
                      onActionBtnClick(item, { _id: "add_up_prices" })
                    }
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </Box>
              </Box>

              <Box className={styles.modifierItemList}>
                <SortableList
                  onSortEnd={(oldIndex, newIndex) =>
                    onSortEndMenuItem(oldIndex, newIndex, item)
                  }
                >
                  {item?.items?.map((y, i2) => {
                    let default_val = y?._id == item?.defaultSelection;
                    return (
                      <SortableItem key={i2}>
                        <Box
                          className={styles.itemView}
                          style={{
                            backgroundColor: theme_mode.bg8,
                            borderBottom:
                              item?.items?.length == i2 + 1
                                ? "0"
                                : "1px solid #D6D6D6",
                          }}
                          px={1.4}
                          py={1}
                          onClick={() =>
                            onActionBtnClick(item, { _id: "default" }, y)
                          }
                        >
                          <Box className={styles.itemNameView}>
                            <Tooltip
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    marginTop: "0px !important",
                                    marginBottom: "0px !important",
                                    backgroundColor: "#EEE",
                                    color: "#000",
                                  },
                                },
                              }}
                              title={
                                default_val ? "Remove Default" : "Set Default"
                              }
                              placement="top"
                            >
                              {default_val ? (
                                <CircleDotIcon height={20} width={20} />
                              ) : (
                                <CircleIcon height={20} width={20} />
                              )}
                            </Tooltip>
                            <Typography
                              ml={1}
                              className={styles.itemNameText}
                              style={{ color: theme_mode.text }}
                            >
                              {y.name}
                            </Typography>
                          </Box>
                          <Box className={styles.itemRightView}>
                            <Box className={styles.itemPriceView} mr={1}>
                              <Typography
                                className={styles.itemPriceText}
                                style={{ color: theme_mode.text }}
                              >
                                {props?.currency
                                  ? CURRENCY_SYMBOL[props.currency]
                                  : CURRENCY_SYMBOL[y.price.currency]}
                                {y.price.value.toFixed(2)}
                              </Typography>
                            </Box>
                            <Box
                              mr={1}
                              sx={{
                                cursor: "pointer",
                                path: { stroke: theme_mode.iconBg },
                                zIndex: 20,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                onRemoveMenuItem(item, y);
                              }}
                            >
                              <Delete1Icon width={15} height={15} />
                            </Box>
                            <ExpandVerticalIcon width={20} height={20} />
                          </Box>
                        </Box>
                      </SortableItem>
                    );
                  })}
                </SortableList>
              </Box>
            </>
          ) : null}
          <Box my={1} px={1.4} className={styles.actionBtnView}>
            {(item._id === "add" ? ACTION_BTN_LIST2 : ACTION_BTN_LIST)
              .filter(
                (x) => (x?._id !== "add_item" && !isEditable) || isEditable
              )
              .map((btn, i3) => {
                return (
                  <Box
                    key={i3}
                    onClick={() => handlerOnActionClick(item, btn)}
                    className={styles.actionBtn}
                    style={{
                      backgroundColor: theme_mode.black,
                      width:
                        item._id === "add" ? "49%" : isEditable ? "32%" : "49%",
                    }}
                  >
                    <Typography
                      className={styles.actionBtnText}
                      style={{ color: theme_mode.btnText }}
                      mr={1}
                    >
                      {btn._id === "edit_item" &&
                      editItemData !== null &&
                      item._id === editItemData?._id
                        ? "Save"
                        : btn.label}
                    </Typography>
                    {item._id == "add" ? null : btn.icon}
                  </Box>
                );
              })}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default ModifierItemList;
