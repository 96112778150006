import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";

import * as Api from "../../api";

import { connect } from "react-redux";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import FromToDatePicker from "../../components/FromToDatePicker";

import styles from "./Analytics.module.css";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { ReactComponent as UserIcon } from "../../assets/images/user2.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/eye.svg";

import { FOOTER_XPADDING } from "../../constants";
import { THEME_MODE } from "../../constants/Theme";

const Analytics = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [filterUserList, setFilterUserList] = useState([]);
  const [viewedItemList, setViewedItemList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [error, setError] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getUserEnquiries();
  }, []);

  useEffect(() => {
    let list = JSON.parse(JSON.stringify(userList));
    if (fromDate && toDate) {
      setFilterUserList(
        list.filter(
          (x) =>
            moment(x.createdAt).format("DD MMM Y") >= fromDate &&
            moment(x.createdAt).format("DD MMM Y") <= toDate
        )
      );
    } else {
      if (fromDate) {
        setFilterUserList(
          list.filter((x) => moment(x.createdAt).format("DD MMM Y") == fromDate)
        );
      } else if (toDate) {
        setFilterUserList(
          list.filter((x) => moment(x.createdAt).format("DD MMM Y") == toDate)
        );
      } else {
        setFilterUserList(list);
      }
    }

    getViewItems(
      fromDate ? moment(fromDate, "DD MMM YYYY").format("YYYY-MM-DD") : null,
      toDate ? moment(toDate, "DD MMM YYYY").format("YYYY-MM-DD") : null
    );
  }, [fromDate, toDate]);

  const removeDupes = (arr, map = new Map()) => {
    arr.forEach((o) => map.set(o.phoneNumber, o));

    return [...map.values()];
  };

  const getUserEnquiries = () => {
    setLoading(true);
    let filter = `?pageSize=1000000000000&pageNum=1&filter__idRestaurant=${props.restaurantId}`;
    Api.getUserEnquiries(filter).then((response) => {
      if (response.success) {
        setUserList(response.data.rows);
        setFilterUserList(response.data.rows);
        if (response.data.rows.length) {
          let rows = removeDupes(response.data.rows);
          setUserList(rows);
          setFilterUserList(rows);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const getViewItems = async (from, to) => {
    try {
      setLoading(true);
      let startDate = from ? from : to ? to : "2023-11-01";
      let endDate = to ? to : from ? from : "today";
      let restId = props.restaurantId;

      const requestBody = {
        dateRanges: [{ startDate, endDate }],
        dimensions: [
          { name: "eventName" },
          { name: "customEvent:restaurant_id" },
          { name: "customEvent:item_id" },
        ],
        metrics: [{ name: "eventCount" }],
        dimensionFilter: {
          andGroup: {
            expressions: [
              {
                filter: {
                  fieldName: "eventName",
                  inListFilter: {
                    values: ["menu_view"],
                  },
                },
              },
              {
                filter: {
                  fieldName: "customEvent:restaurant_id",
                  inListFilter: {
                    values: [restId],
                  },
                },
              },
            ],
          },
        },
        limit: "3",
        orderBys: [
          {
            desc: true,
            metric: {
              metricName: "eventCount",
            },
          },
        ],
      };

      let data = { requestBody: requestBody };
      data = JSON.stringify(data);
      Api.getAnalytics(data).then((response) => {
        if (response.success) {
          const responseData = response.data;
          let header = responseData.dimensionHeaders;
          let itemIndex = header.findIndex(
            (x) => x.name == "customEvent:item_id"
          );

          let row = responseData?.rows;
          if (row?.length) {
            let list = [];
            if (itemIndex >= 0) {
              row.map((x) => {
                let item_id = x?.dimensionValues?.[itemIndex]?.["value"]
                  ? x?.dimensionValues?.[itemIndex]?.["value"]
                  : null;
                if (item_id) {
                  let item_data = props.restaurantMenuList.filter(
                    (x) => x._id == item_id
                  );
                  let menu = "";
                  if (item_data.length) {
                    menu = item_data[0]["name"];
                  }
                  list.push({
                    item_id: item_id,
                    menu: menu,
                    count: x?.metricValues?.[0]?.["value"]
                      ? x.metricValues?.[0]?.["value"]
                      : 0,
                  });
                }
              });
            }
            setViewedItemList(list);
          } else {
            setViewedItemList([]);
          }
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onResetClick = () => {
    setFromDate(null);
    setToDate(null);
    setError(null);
    getUserEnquiries(null, null);
  };

  const handlerOnBackBtnClick = () => {
    navigate("/settings");
  };

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0.5}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid item xs={12}>
          <Grid mt={1} mb={5} container item xs={12}>
            <Grid item xs={6}>
              <Box onClick={handlerOnBackBtnClick} className={styles.backBtn}>
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <FromToDatePicker
                themeMode={props.themeMode}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                error={error}
                showReset={true}
                showIcon={true}
                onReset={onResetClick}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
          >
            <Box
              p={2}
              mx={1.5}
              className={styles.cardView}
              sx={{ backgroundColor: theme_mode["card6"] }}
            >
              <Box className={styles.cardTopView}>
                <Box className={styles.cardIconView}>
                  <UserIcon width={50} height={50} />
                </Box>
                <Box>
                  <Typography className={styles.userCount}>
                    {filterUserList.length}
                  </Typography>
                  <Typography className={styles.userText}>
                    {filterUserList.length <= 1 ? "USER" : "USERS"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography className={styles.descText}>
                  <li>
                    We have {filterUserList.length}{" "}
                    {filterUserList.length <= 1 ? "user" : "users"}
                  </li>
                </Typography>
                <Box
                  className={styles.viewBtn}
                  onClick={() => navigate("/user_enquiries")}
                >
                  <Typography className={styles.viewBtnText} ml={0.5}>
                    View all users
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box p={2} mx={1.5} className={styles.itemCardView}>
              <Typography mt={1} mb={2} className={styles.topViewedText}>
                Top viewed items
              </Typography>
              <Box mb={3}>
                {viewedItemList.map((x, i) => {
                  return (
                    <Box key={i} pb={1} pt={1} className={styles.itemView}>
                      <Box className={styles.itemNameView}>
                        <Typography className={styles.itemName}>
                          {x.menu}
                        </Typography>
                      </Box>
                      <Box className={styles.itemCountView}>
                        <ViewIcon width={14} height={14} />
                        <Typography ml={0.5} className={styles.itemCount}>
                          {parseInt(x.count) + 4000}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {viewedItemList.length == 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    py={5}
                    sx={{
                      color: "#FFF",
                      fontSize: 12,
                      fontFamily: "InterSemiBold",
                      textAlign: "center",
                    }}
                  >
                    No Viewed Items
                  </Typography>
                </Box>
              ) : null}
              <Box
                className={styles.viewBtn}
                sx={{ backgroundColor: theme_mode["theme"] }}
                onClick={() => navigate("/view_items")}
              >
                <Typography
                  className={styles.viewBtnText}
                  sx={{ color: theme_mode["text"] }}
                  ml={0.5}
                >
                  View all items
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    restaurantMenuList: state.userData.restaurantMenuList,
  };
};

export default connect(mapStateToProps, null)(Analytics);
