import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import styles from "./ModifierMenuItems.module.css";

const ModifierMenuItems = (props) => {
  const { menuList, header, selectedItems } = props;

  const handlerOnSelectMenu = (menu) => {
    props.onSelectMenu(menu);
  };

  const MenuListView = ({ menu }) => {
    const checked = selectedItems?.includes(menu?._id);

    return (
      <FormControlLabel
        className={`${styles["checkbox-main-view"]}`}
        control={
          <Box className={`${styles["checkbox-view"]}`}>
            <Checkbox
              defaultChecked={checked}
              onChange={() => handlerOnSelectMenu(menu)}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 18 },
                color: "#FFF",
                "&.Mui-checked": {
                  color: "#FFF",
                },
              }}
            />
            <Typography className={`${styles["menu-text"]}`}>
              {menu.name}
            </Typography>
          </Box>
        }
        label={""}
      />
    );
  };

  return (
    <Box className={`${styles["main-view"]}`}>
      {[
        ...menuList.filter((x) => selectedItems?.includes(x?._id)),
        ...menuList.filter((x) => !selectedItems?.includes(x?._id)),
      ].map((menu, index) => {
        return <MenuListView key={index} menu={menu} />;
      })}
    </Box>
  );
};

export default ModifierMenuItems;
