import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";
import { updateDrawerOpen } from "../redux/actions/userDataActions";

import { ReactComponent as Menu } from "../assets/images/sidebar_menu.svg";
import { ReactComponent as Settings } from "../assets/images/settings.svg";
import { ReactComponent as LogOutIcon } from "../assets/images/logout.svg";
import { ReactComponent as Order } from "../assets/images/order.svg";
import { ReactComponent as Dining } from "../assets/images/dining.svg";
import { ReactComponent as GiftCardIcon } from "../assets/images/gift_card.svg";
import { ReactComponent as BookingsIcon } from "../assets/images/bookings.svg";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
import { ReactComponent as ManageMenuIcon } from "../assets/images/manage_menu.svg";
import { ReactComponent as AnalyticsIcon } from "../assets/images/analytics.svg";

import Navbar from "./Navbar";

import {
  APP_TYPE_BOTH,
  APP_TYPE_GOPOSH,
  IS_LARGE_SCREEN,
  RESTAURANT_ADMIN_ROLE_ID,
  RESTAURANT_USER_ROLE_ID,
} from "../constants";
import { THEME_MODE } from "../constants/Theme";

export const drawerWidth = IS_LARGE_SCREEN ? 280 : 240;
const drawerWindowWidth = 75;
const ICON_SiZE = IS_LARGE_SCREEN ? 30 : 22;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Sidebar = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(props.drawerOpen);
  const [mobileView, setMobileView] = useState(window.innerWidth < 600);

  let menu_list = [];

  const appType = props.restaurantDetail?.appType;

  if (props.restaurantId) {
    menu_list = [
      {
        page: "customers",
        label: "Customers",
        icon: <UserIcon width={ICON_SiZE} height={ICON_SiZE} />,
        href: "/customers",
        svgStroke: false,
        svgFill: true,
      },
      {
        page: "bookings",
        label: "Bookings",
        icon: <BookingsIcon width={ICON_SiZE} height={ICON_SiZE} />,
        href: "/bookings",
        svgStroke: false,
        svgFill: true,
      },
      {
        page: "gift_cards",
        label: "Gift Cards",
        icon: <GiftCardIcon width={ICON_SiZE} height={ICON_SiZE} />,
        href: "/gift_cards",
        svgStroke: false,
        svgFill: true,
      },
    ];
    if (props.userRole === RESTAURANT_ADMIN_ROLE_ID) {
      menu_list = [
        ...menu_list,
        {
          page: "settings",
          label: "Settings",
          icon: <Settings width={ICON_SiZE} height={ICON_SiZE} />,
          href: "/settings",
          svgStroke: true,
          svgFill: false,
        },
      ];
    } else if (props.userRole === RESTAURANT_USER_ROLE_ID) {
    } else {
      menu_list = [];
    }
  } else {
    menu_list = [
      ...menu_list,
      {
        page: "settings",
        label: "Settings",
        icon: <Settings width={ICON_SiZE} height={ICON_SiZE} />,
        href: "/add_restaurant",
        svgStroke: true,
        svgFill: false,
      },
    ];
  }

  useEffect(() => {
    window.addEventListener("resize", function () {
      setMobileView(window.innerWidth <= 990);
    });
  }, []);

  useEffect(() => {
    if (appType === APP_TYPE_GOPOSH || appType === APP_TYPE_BOTH) {
      setMobileOpen(props.drawerOpen);
    } else {
      setMobileOpen(false);
    }
  }, [props.drawerOpen]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    props.updateDrawerOpen(!mobileOpen);
  };

  const MenuList = ({ x }) => {
    return (
      <ListItem
        button
        selected={props.page === x.page}
        key={x.label}
        className={props.page === x.page ? "" : "sidebar-not-active-hover"}
        style={
          props.page === x.page
            ? {
                backgroundColor: theme_mode.bg,
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
              }
            : null
        }
        sx={styles.listItem}
        onClick={() =>
          x.page == "logout"
            ? (window.location.href = x.href)
            : navigate(x.href)
        }
      >
        <ListItemIcon
          sx={{
            ...styles.listItemIcon,
            path: {
              fill: x.svgFill ? theme_mode.iconBg : "none",
              stroke: x.svgStroke ? theme_mode.iconBg : "none",
            },
          }}
        >
          {x.icon}
        </ListItemIcon>
        <ListItemText
          primary={x.label}
          sx={{
            color: theme_mode.text,
            "& .MuiTypography-root": {
              fontFamily: "InterSemiBold",
              fontSize: IS_LARGE_SCREEN ? 24 : { xl: 15, md: 18 },
            },
            opacity: 1,
          }}
        />
        {props.page === x.page ? (
          <>
            <div
              className="hover-top-cut"
              style={{ backgroundColor: theme_mode.sidebar }}
            ></div>
            <div
              className="hover-top-white"
              style={{ backgroundColor: theme_mode.bg }}
            ></div>
            <div
              className="hover-bottom-cut"
              style={{ backgroundColor: theme_mode.sidebar }}
            ></div>
            <div
              className="hover-bottom-white"
              style={{ backgroundColor: theme_mode.bg }}
            ></div>
          </>
        ) : null}
      </ListItem>
    );
  };

  const SideDrawer = () => {
    return (
      <Box sx={styles.mainBox}>
        <DrawerHeader
          style={{ justifyContent: "center", marginBottom: 0 }}
        ></DrawerHeader>
        <List
          style={{
            paddingLeft: !mobileOpen ? 10 : 25,
            minHeight: window.innerHeight - (IS_LARGE_SCREEN ? 165 : 150),
          }}
        >
          {menu_list.map((x, i) => {
            return <MenuList key={i} x={x} />;
          })}
        </List>
        <Box style={{ paddingLeft: !mobileOpen ? 10 : 25 }}>
          <MenuList
            x={{
              page: "logout",
              label: (
                <Typography sx={{ width: "fit-content" }}>
                  <Typography sx={styles.usernameText}>
                    {props.userDetail?.name}
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>Logout</Typography>
                </Typography>
              ),
              icon: <LogOutIcon width={ICON_SiZE} height={ICON_SiZE} />,
              href: props.restaurantId ? "/logout/pin" : "/logout",
              svgStroke: false,
              svgFill: true,
            }}
          />
        </Box>
      </Box>
    );
  };

  const container =
    props.window !== undefined ? () => props.window().document.body : undefined;

  let show_navbar = true;
  if (typeof props.showNavbar != "undefined") {
    show_navbar = props.showNavbar;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {show_navbar ? (
        <Navbar
          showLogo={true}
          position={"fixed"}
          page={props?.page ? props.page : ""}
          navTitle={props?.navTitle ? props.navTitle : ""}
          open={mobileOpen}
          handleDrawer={handleDrawerToggle}
        />
      ) : null}
      {appType === APP_TYPE_GOPOSH || appType === APP_TYPE_BOTH ? (
        <Box
          component="nav"
          id={"sidebarViewId"}
          sx={{
            width: { sm: !mobileOpen ? 0 : drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileView ? mobileOpen : false}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: !mobileOpen ? drawerWindowWidth : drawerWidth,
                borderRightWidth: 0,
              },
            }}
          >
            <SideDrawer />
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: !mobileOpen ? 0 : drawerWidth,
                borderRightWidth: 0,
              },
            }}
            open
          >
            <SideDrawer />
          </Drawer>
        </Box>
      ) : null}
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDrawerOpen: (data) => dispatch(updateDrawerOpen(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.userData.drawerOpen,
    restaurantId: state.userData.restaurantId,
    restaurantDetail: state.userData.restaurantDetail,
    userRole: state.userData.userRole,
    themeMode: state.userData.themeMode,
    userDetail: state.userData.userDetail,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

const styles1 = (Theme) => ({
  mainBox: {
    backgroundColor: Theme.sidebar,
    paddingTop: 1,
    height: "100%",
    overflow: "hidden",
  },
  listItem: {
    justifyContent: "initial",
    height: IS_LARGE_SCREEN ? 70 : 48,
    px: 2.5,
  },
  listItemIcon: {
    justifyContent: "center",
    minWidth: 0,
    mr: 3,
    zIndex: 3,
  },
  usernameText: {
    fontSize: IS_LARGE_SCREEN
      ? "15px !important"
      : { xl: "13px !important", md: "15px !important" },
    fontFamily: "InterSemiBold",
    textTransform: "capitalize",
    textAlign: "center",
    color: "#F17400",
  },
});
