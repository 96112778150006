import React, { useCallback, useEffect, useState } from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { connect } from "react-redux";

import { ReactComponent as EditIcon } from "./../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "./../assets/images/delete1.svg";
import { ReactComponent as FileIcon } from "./../assets/images/file.svg";

import { THEME_MODE } from "../constants/Theme";

const SettingCategoryList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme_mode.tableHeaderText2,
      fontFamily: "InterBold",
      fontSize: 14,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: `${props.themeMode == "dark" ? 5 : 1}px solid ${
        theme_mode.tableBorder
      }`,
      color: theme_mode.tableRowText,
      fontFamily: "InterMedium",
      fontSize: 13,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme_mode.tableRow2,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme_mode.tableRow,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let data = props.categoryList;

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: props.themeMode == "dark" ? 0 : 5,
        "&.MuiTableContainer-root": { backgroundColor: theme_mode.table },
      }}
      elevation={0}
    >
      <Table aria-label="customized table">
        <TableHead sx={{ backgroundColor: theme_mode.tableHeader2 }}>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell sx={{ borderBottom: "none" }}>
              <Box>Category Title</Box>
            </StyledTableCell>

            <StyledTableCell sx={{ borderBottom: "none" }}>
              <Box>Description</Box>
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                <Box sx={styles.imageBox}>
                  {item.images.length > 0 ? (
                    <img
                      src={item.images[0]}
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <FileIcon height={30} width={30} />
                  )}
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={styles.nameBox}>{item.name}</Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={styles.descBox}>{item.description}</Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={styles.actionBox}>
                  <Box sx={{ path: { fill: theme_mode.iconBg2 } }}>
                    <EditIcon
                      height={20}
                      width={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => props.editCategory(item)}
                    />
                  </Box>
                  <Box sx={{ path: { stroke: theme_mode.iconBg2 } }}>
                    <DeleteIcon
                      height={20}
                      width={20}
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() => props.deleteCategory(item)}
                    />
                  </Box>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          {props.themeMode == "dark" ? null : (
            <StyledTableRow sx={{ height: 70 }} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SettingCategoryList);

const styles1 = (Theme) => ({
  imageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nameBox: {
    minWidth: 120,
  },
  descBox: {},
  actionBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  arrowIconBox: {
    float: "right",
    marginRight: 5,
  },
  arrowIcon: {
    cursor: "pointer",
    lineHeight: 0,
  },
});
