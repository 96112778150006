import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import styles from "./OrderHistory.module.css";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import SettingDatePicker from "../../components/SettingDatePicker";
import OrderHistoryDetailModal from "../../components/OrderHistoryDetailModal";
import Page from "../../components/Page";

import { THEME_MODE } from "../../constants/Theme";

import {
  DELIVERY,
  DINEIN,
  FOOTER_XPADDING,
  NAVBAR_HEIGHT,
  PICKUP,
} from "../../constants";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { ReactComponent as CalenderIcon } from "../../assets/images/calender_gray.svg";
import { onSetCartModifierData } from "../../constants/CommonFunc";

let FILTER_LIST = [
  { _id: "all", name: "All Orders" },
  { _id: "online", name: "Online Orders" },
  { _id: "dining", name: "Dining Orders" },
];

let DATE_FILTER_LIST = [
  { _id: "date", name: "" },
  { _id: "today", name: "Today" },
  { _id: "this_week", name: "This Week" },
  { _id: "this_month", name: "This Month" },
];

const OrderHistory = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [orderTypeFilter, setOrderTypeFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState("");
  const [dateVal, setDateVal] = useState("");
  const [orderHistoryDetail, setOrderHistoryDetail] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  let orderTypeFilterBg = { true: theme_mode.btnBg, false: theme_mode.bg9 };
  let orderDateFilterBg = { true: theme_mode.black, false: theme_mode.bg8 };
  let orderTypeFilterText = {
    true: theme_mode.btnText,
    false: theme_mode.text9,
  };

  useEffect(() => {
    getMenuList();
  }, []);

  useEffect(() => {
    if (menuList.length) {
      getOrderList(1);
    }
  }, [menuList, orderTypeFilter, dateFilter, dateVal]);

  const getMenuList = () => {
    setIsLoading(true);
    let filter = "?pageSize=30000&pageNum=1&orderBy=order&orderByDir=asc";
    Api.getMenuList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setMenuList(data);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getOrderList = (num) => {
    setIsLoading(true);
    let filter =
      "pageSize=20&pageNum=" +
      num +
      "&filter_orderStatus=" +
      '["completed"]' +
      "&orderBy=createdAt&orderByDir=desc";
    if (orderTypeFilter == "dining") {
      filter = filter + "&filter_orderType=[" + DINEIN + "]";
    } else if (orderTypeFilter == "online") {
      filter = filter + "&filter_orderType=[" + DELIVERY + "," + PICKUP + "]";
    } else {
      filter =
        filter +
        "&filter_orderType=[" +
        DINEIN +
        "," +
        DELIVERY +
        "," +
        PICKUP +
        "]";
    }
    if (dateFilter != "") {
      let fromDate = "";
      let toDate = "";
      if (dateFilter == "date" && dateVal != "") {
        fromDate = moment(dateVal, "DD MMM YYYY").format("Y-MM-DD");
        toDate = moment(dateVal, "DD MMM YYYY")
          .add(1, "days")
          .format("Y-MM-DD");
      } else if (dateFilter == "today") {
        fromDate = moment().format("Y-MM-DD");
        toDate = moment().add(1, "days").format("Y-MM-DD");
      } else if (dateFilter == "this_week") {
        fromDate = moment().startOf("week").format("Y-MM-DD");
        toDate = moment().endOf("week").add(1, "days").format("Y-MM-DD");
      } else if (dateFilter == "this_month") {
        fromDate = moment().startOf("month").format("Y-MM-DD");
        toDate = moment().endOf("month").add(1, "days").format("Y-MM-DD");
      }
      filter =
        filter +
        "&filter_dateRangeFrom=" +
        fromDate +
        "&filter_dateRangeTo=" +
        toDate;
    }

    Api.getOrders(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setPageNum(num);
        setTotalPages(response.data.pages);

        let list = [];

        data
          .filter((y) => y?.orderStatus == "completed")
          .map((x, i) => {
            let cart_list = [];
            let currency = "";
            x.cart.map((y) => {
              let findIndex = menuList.findIndex((z) => z._id == y._idMenuItem);
              if (findIndex >= 0) {
                let cart_data = {
                  ...y,
                  ...menuList[findIndex],
                  name: y?.menuItemName
                    ? y.menuItemName
                    : menuList[findIndex]["name"],
                  price: y?.price ? y?.price : menuList[findIndex]["price"],
                  cartModifier: onSetCartModifierData(
                    y?.modifiers ?? [],
                    menuList
                  ),
                };
                cart_list.push(cart_data);

                if (currency == "") {
                  currency = cart_data["price"]?.currency;
                }
              }
            });

            if (currency == "") {
              currency = props.restaurantDetail?.currecnyIcon
                ? props.restaurantDetail.currecnyIcon
                : "";
            }
            let address = "";
            if (x?.userDetails?.address?.shipping) {
              let address1 = x?.userDetails?.address?.shipping;
              if (address1.addressTitle) {
                address = address1.addressTitle + ", ";
              }
              address =
                address +
                address1.address +
                ", " +
                address1.country +
                ", " +
                address1.city +
                ", " +
                ", " +
                address1.pincode;
            }

            let list_item = {
              ...x,
              date: moment(x.createdAt).format("DD MMM Y, HH:mm A"),
              name: x?.userDetails?.name ? x?.userDetails.name : "",
              price: { value: x.totalAmount, currency: currency },
              address: address ? address : "",
              phone: x?.userDetails?.phone
                ? x?.userDetails?.phone.code + x?.userDetails?.phone.number
                : "",
              cart: cart_list,
            };
            list.push(list_item);
          });

        let sortList = [];

        list.reverse().map((item, index) => {
          let date = moment(item.createdAt).format("DD MMM Y");
          let dataIndex = sortList.findIndex((x) => x?.date === date);
          if (dataIndex >= 0) {
            sortList[dataIndex]["list"] = [
              ...sortList[dataIndex]["list"],
              item,
            ];
          } else {
            sortList.push({ date: date, list: [item] });
          }
        });

        setOrderList(sortList);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const onSelectPage = (value) => {
    getOrderList(value);
  };

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={1}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Grid
          container
          item
          md={12}
          p={1.5}
          style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
        >
          <Grid container item xs={12}>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <Box
                onClick={() => navigate("/settings")}
                sx={{
                  cursor: "pointer",
                  width: "fit-content",
                  lineHeight: "0.5",
                }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>
            <Grid item xs={8} className={styles.orderTypeFilterMainView}>
              <Box
                className={styles.orderTypeFilterListView}
                style={{ backgroundColor: orderTypeFilterBg[false] }}
              >
                {FILTER_LIST.map((x, i) => {
                  return (
                    <Box
                      key={i}
                      onClick={() => {
                        setOrderTypeFilter(x._id);
                        setDateFilter("");
                        setDateVal("");
                      }}
                      className={styles.orderTypeFilterView}
                      style={{
                        backgroundColor:
                          orderTypeFilterBg[x._id == orderTypeFilter],
                      }}
                    >
                      <Typography
                        className={styles.orderTypeFilterText}
                        style={{
                          color: orderTypeFilterText[x._id == orderTypeFilter],
                        }}
                      >
                        {x.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid item xs={12} mt={2} className={styles.dateFilterMainView}>
            <Box
              className={styles.orderTypeFilterListView}
              style={{ backgroundColor: orderDateFilterBg[false] }}
            >
              {DATE_FILTER_LIST.map((x, i) => {
                return (
                  <Box key={i} className={styles.dateFilterMainView}>
                    <Box
                      onClick={() => {
                        if (x._id == "date") {
                        } else {
                          setDateFilter(x._id);
                          setDateVal("");
                        }
                      }}
                      className={styles.dateFilterView}
                      style={{
                        backgroundColor: orderDateFilterBg[x._id == dateFilter],
                      }}
                    >
                      {x._id == "date" &&
                      (dateFilter != "date" ||
                        (dateFilter == "date" && dateVal != "")) ? (
                        <Box
                          style={{
                            width: dateVal == "" ? 30 : 100,
                          }}
                        >
                          <SettingDatePicker
                            value={dateVal}
                            inputFormat="DD-MM-YYYY"
                            onChange={(newValue) => {
                              setDateFilter("date");
                              setDateVal(newValue.format("DD MMM YYYY"));
                            }}
                            labelStyle={{
                              fontSize: 14,
                              fontFamily: "InterBold",
                            }}
                            inputFontSize={12}
                            height={"100%"}
                            label={""}
                            placeholder={""}
                            color={theme_mode.btnText}
                            backgroundColor={
                              orderDateFilterBg[x._id == dateFilter]
                            }
                            borderRadius={"10px"}
                            cursor={"pointer"}
                            hideInput={dateVal != ""}
                            inputTextAlign={"center"}
                            marginLeft={"8px"}
                            marginRight={"0px"}
                            leftView={
                              <Box
                                mt={-1.3}
                                ml={-1.3}
                                sx={{
                                  cursor: "pointer",
                                  path: {
                                    fill: theme_mode.iconBg2,
                                  },
                                }}
                              >
                                <CalenderIcon width={17} height={17} />
                              </Box>
                            }
                            rightView={null}
                          />
                        </Box>
                      ) : (
                        <Typography
                          className={styles.orderTypeFilterText}
                          style={{
                            color: orderTypeFilterText[x._id == dateFilter],
                          }}
                        >
                          {x.name}
                        </Typography>
                      )}
                    </Box>
                    {i < DATE_FILTER_LIST.length - 1 ? (
                      <Box
                        style={{
                          height: "40%",
                          width: 1,
                          backgroundColor: theme_mode.text4,
                        }}
                      />
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item md={12} className={styles.orderList}>
            {orderList.map((t, i2) => {
              let order_list = t?.list.filter(
                (x) =>
                  ((x.orderType == DELIVERY || x.orderType == PICKUP) &&
                    orderTypeFilter == "online") ||
                  (x.orderType == DINEIN && orderTypeFilter == "dining") ||
                  orderTypeFilter == "all"
              );
              if (order_list.length == 0) {
                return null;
              }
              return (
                <Box mt={3} key={i2}>
                  <Typography
                    className={styles.orderDateTitleText}
                    style={{ color: theme_mode.text }}
                  >
                    {t.date == moment().format("DD MMM Y") ? "Today" : t.date}
                  </Typography>
                  <Box mt={1}>
                    {order_list.map((x, i) => {
                      return (
                        <Box
                          key={i}
                          className={styles.orderListView}
                          style={{ backgroundColor: theme_mode.card }}
                          onClick={() => setOrderHistoryDetail(x)}
                          mb={2}
                          px={2}
                          py={1}
                        >
                          <Box className={styles.orderDataSubView}>
                            <Typography
                              className={styles.orderIdText}
                              style={{ color: theme_mode.text }}
                            >
                              Order #{x?._id?.toString().substr(-5)}
                            </Typography>
                            <Typography
                              mt={1}
                              className={styles.orderDateText}
                              style={{ color: theme_mode.text }}
                            >
                              {x?.date}
                            </Typography>
                          </Box>
                          <Box className={styles.orderSubView}>
                            <Box
                              className={styles.orderTypeView}
                              style={{
                                backgroundColor:
                                  x.orderType == DELIVERY ||
                                  x.orderType == PICKUP
                                    ? theme_mode.onlineOrder
                                    : theme_mode.tableOrder,
                              }}
                            >
                              <Typography
                                className={styles.orderTypeText}
                                style={{
                                  color: theme_mode.lightText,
                                }}
                              >
                                {x.orderType == DELIVERY ||
                                x.orderType == PICKUP
                                  ? "Online Order"
                                  : "Table Order"}
                              </Typography>
                            </Box>
                            {x.name ? (
                              <Typography
                                mt={0.5}
                                className={styles.orderNameText}
                                style={{ color: theme_mode.text }}
                              >
                                Name: {x.name}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box className={styles.orderPriceSubView}>
                            <Box className={styles.orderPriceView}>
                              <Typography
                                className={styles.orderPriceText}
                                style={{ color: theme_mode.text }}
                              >
                                Price
                              </Typography>
                              <Typography
                                mt={1}
                                style={{ color: theme_mode.text }}
                                className={styles.orderPriceValText}
                              >
                                {x.price?.currency} {x.price?.value?.toFixed(2)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Grid>
          <Grid container mt={3} item xs={12} justifyContent={"center"}>
            {totalPages > 0 ? (
              <Page
                themeMode={props.themeMode}
                totalPages={totalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            ) : null}
          </Grid>
        </Grid>
      )}
      <OrderHistoryDetailModal
        data={orderHistoryDetail}
        themeMode={props.themeMode}
        onClose={() => setOrderHistoryDetail(null)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantId: state.userData.restaurantId,
    restaurantDetail: state.userData.restaurantDetail,
  };
};

export default connect(mapStateToProps, null)(OrderHistory);
