import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./../assets/css/custom.css";

import { connect } from "react-redux";

import { THEME_MODE } from "../constants/Theme";

const theme = (theme_mode, tagColor) =>
  createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          tag: {
            backgroundColor: tagColor ? tagColor : theme_mode.inputBg5,
            "& .MuiChip-deleteIcon": {
              color: theme_mode.text,
            },
          },
          listbox: {
            backgroundColor: theme_mode.card,
          },
          option: {
            backgroundColor: theme_mode.card,
            color: theme_mode.text,
            borderBottom: "1px solid #DDD",
            "&.Mui-focused": {
              backgroundColor: theme_mode.inputBg2 + " !important",
            },
            '&[aria-selected="true"]': {
              backgroundColor: theme_mode.inputBg5 + " !important",
            },
          },
          clearIndicator: {
            color: theme_mode.text,
          },
          popupIndicator: {
            color: theme_mode.text,
          },
        },
      },
    },
  });

const SelectizeTextField = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  let bgColor =
    props?.editable != false
      ? props?.backgroundColor
        ? props.backgroundColor
        : theme_mode.inputBg
      : props?.disabledBg
      ? props.disabledBg
      : theme_mode.inputDisabledBg;
  return (
    <Box style={{ width: "100%" }}>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[
              {
                color: theme_mode.inputLabelText,
                fontSize: 15,
                fontFamily: "InterSemiBold",
              },
              props?.labelStyle ? props.labelStyle : null,
            ]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflow: "hidden",
          pl: props?.leftView ? 1.5 : 0,
          pr: props?.rightView ? 1.5 : 0,
          backgroundColor: bgColor,
          border: props?.border ? props.border : "0px",
          borderRadius: props?.borderRadius ? props.borderRadius : "12px",
          width: props?.width ? props.width : "100%",
        }}
      >
        <ThemeProvider
          theme={theme(theme_mode, props?.tagColor ? props.tagColor : null)}
        >
          <Autocomplete
            multiple={
              typeof props?.multiple !== "undefined" ? props?.multiple : true
            }
            size="small"
            options={props.options}
            onChange={(e, val) => props.onChange(val)}
            value={props.value}
            sx={{
              "&.MuiAutocomplete-root": {
                width: "100%",
              },
            }}
            ListboxProps={{
              sx: {
                fontSize: 12,
                fontFamily: "InterMedium",
              },
            }}
            ChipProps={{
              sx: {
                "& .MuiChip-label": {
                  fontSize: 12,
                  fontFamily: "InterMedium",
                  color: theme_mode.text,
                },
              },
            }}
            disableClearable={props?.disableClearable == true}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={props?.variant ? props?.variant : "standard"}
                label={""}
                placeholder={props?.placeholder ? props?.placeholder : ""}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "13px !important",
                    fontFamily: "InterMedium",
                    color: props?.placeholderColor
                      ? props.placeholderColor
                      : theme_mode.inputLabel2,
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: bgColor,
                    minHeight: 46,
                    paddingTop: "8px !important",
                    paddingBottom: "8px !important",
                    "&:hover": {
                      backgroundColor: bgColor,
                    },
                    "&.Mui-focused": {
                      backgroundColor: bgColor,
                    },
                    "&.Mui-disabled": {
                      backgroundColor: bgColor,
                    },
                    "&:before, :after, :hover:not(.Mui-disabled):before": {
                      borderBottom: props?.borderBottom
                        ? props.borderBottom
                        : 0,
                    },
                    "& .MuiFilledInput-input": {
                      width: "100% !important",
                    },
                  },
                }}
              />
            )}
          />
        </ThemeProvider>
      </Box>
      <Box>
        {props?.error ? (
          <Typography
            sx={{
              color: theme_mode.redText,
              fontSize: 10,
              fontFamily: "InterMedium",
            }}
          >
            {props.error}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SelectizeTextField);
